import Dashboard from "../components/admin/Dashboard";

const AdminPage = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default AdminPage;
